<template>
  <div>
    <v-data-table :headers="headers" :items="items" dense :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                flat
                dense
                outlined
                background-color="white"
                v-model="search"
                placeholder="search"
                append-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    dark
                    rounded
                    :to="{ name: 'Create Check Balance' }"
                    class="mr-2"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Create</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.no="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:item.detail="{ item }">
        <v-list-item v-for="(data, index) in item.items" :key="index">
          <v-list-item-content>
            <v-list-item-title
              >{{ data.type }} : {{ data.employeeName }}
              {{ data.date ? `(${data.date})` : "" }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn color="success" @click="openDialog(item)">
          update
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <dialog-assign-user
      :dialog="dialog"
      :item="item"
      :employees="employees"
      @save="updateUser"
      @close="close"
    ></dialog-assign-user>
  </div>
</template>

<script>
import moment from "moment";
import DialogAssignUser from "./DialogAssignUser.vue";
import { mapState } from "vuex";

export default {
  name: "check-balance",
  components: {
    DialogAssignUser,
  },
  data: () => ({
    items: [],
    dialog: false,
    item: {},
    date: moment().format("yyyy-MM"),
    search: "",
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Month",
        value: "month",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Detail",
        value: "detail",
        sortable: false,
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),
  computed: {
    ...mapState("external", ["employees"]),
  },
  created() {
    this.initialize();
    if (this.employees.length == 0) {
      this.$store.dispatch("external/getEmployees");
    }
  },

  methods: {
    initialize() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("checkBalance/getCheckBalance", this.date)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    openDialog(item) {
      this.item = item;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    updateUser(item) {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("checkBalance/updateUser", item)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>

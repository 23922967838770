<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Assign To</span>
      </v-card-title>
      <v-card-text>
        <v-row v-for="(data, index) in item.items" :key="index">
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-select
              dense
              outlined
              flat
              background-color="white"
              :items="listType"
              v-model="data.type"
              disabled
              label="Type"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-autocomplete
              outlined
              flat
              dense
              :items="employees"
              item-text="name"
              item-value="hrId"
              background-color="white"
              v-model="data.employeeId"
              label="Assign To"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-menu
              v-model="data.menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="data.date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  label="Date"
                ></v-text-field>
              </template>
              <v-date-picker v-model="data.date" @input="data.menuDate = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close">
          Close
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-asisgn-user",
  props: {
    dialog: Boolean,
    item: Object,
    employees: Array,
  },
  data() {
    return {
      listType: ["User", "Checker", "Approved"],
    };
  },
  methods: {
    save() {
      this.$emit("save", this.item);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
